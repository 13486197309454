import React, { useState } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import EventIcon from '@material-ui/icons/Event';
import SettingsIcon from '@material-ui/icons/Settings';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { LensTwoTone } from '@material-ui/icons';
import Delete from './VideoDelete';
import Edit from './VideoEdit';

export default function VideoCard({ video={}, setVideo, removeFn }) {
  const handleClickDelete = () => {
    setOpenDelete(true);
  }
  const handleClickEdit = () => {
    setOpenEdit(true);
  }
	const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const openLink = (videoId) => {
    window.open('https://www.youtube.com/watch?v='+videoId)
  };

  return (
    <>
      <Card variant="outlined">
        <CardMedia
          style={{cursor: "pointer"}}
          onClick={() => openLink(video.video_id)}
          component="img"
          alt={video.title}
          image={`https://i.ytimg.com/vi/${video.video_id}/mqdefault.jpg`}
          title={video.title}
        />
        <CardContent style={{
          padding: "0.5rem 1.2rem"
        }}>
          <Typography gutterBottom variant="subtitle1" component="h6" dangerouslySetInnerHTML={{__html: video.title}}>
          </Typography>
          {video.category && <Button variant="outlined" size="small">{video.category}</Button>}
          <Typography variant="body2" color="textSecondary" component="p">
            {video.tags}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p" dangerouslySetInnerHTML={{__html: video.description}}>
          </Typography>
          <Typography variant="subtitle2" color="textSecondary"
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "10px"
            }}>
            <div>
              <EventIcon style={{fontSize:"24px", marginRight:"5px"}}/>
              <div style={{
                display:"inline-block",
                verticalAlign: "top",
                paddingTop: "3px"
              }}>{moment(video.created_at).format('ll')}</div>
            </div>
            <div style={{
                display:"inline-block",
                verticalAlign: "top",
                paddingTop: "3px",
                letterSpacing: 10,
                cursor: "pointer"
              }}>
                <EditIcon onClick={handleClickEdit}/>&nbsp;
                <DeleteIcon onClick={handleClickDelete}/>
              </div>
          </Typography>
        </CardContent>
      </Card>
      <Delete open={openDelete} setOpenDelete={setOpenDelete} video={video} removeFn={removeFn}/>
      <Edit open={openEdit} setOpenEdit={setOpenEdit} video={video}/>
    </>
  );
}
