import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  },
}));

export default function DatePicker({ label, display="flex", defaultDate, onDateChange }) {
  const classes = useStyles();
  return (
    <>
      <form className={classes.container} noValidate style={{display}}>
        <TextField
          onChange={(e) => onDateChange(e.target.value)}
          id="date"
          label={label}
          type="date"
          defaultValue={defaultDate}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </form>
    </>
  );
}
