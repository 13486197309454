import React, { useEffect, useState } from 'react';
import { API_SERVER } from '../../Config/constants';
import axios from 'axios';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

export default function Delete({ open, setOpenDelete, evt={}, removeFn }) {
  const handleClose = () => {
    setOpenDelete(false)
  }
  const handleDelete = async () => {
    const res = await axios.delete(`${API_SERVER}/api/chronicle/${evt._id}`)
    removeFn(evt._id)
    setOpenDelete(false)
  }
  return (
    <>
      <Dialog maxWidth="sm" fullWidth={true} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">연혁 삭제</DialogTitle>
        <DialogContent>
          <DialogContentText>
          {evt.year}년도 {evt.title} {evt.when}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            취소
          </Button>
          <Button onClick={handleDelete} color="secondary">
            삭제
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
