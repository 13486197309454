import React, { useState } from 'react';
import moment from 'moment';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import Paper from '@material-ui/core/Paper';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Edit from './BulletinEdit';
import Delete from './BulletinDelete';
import { Button } from '@material-ui/core';
import { PinDropSharp } from '@material-ui/icons';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#D5D5D5',
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

const useStyles = makeStyles({
  table: {
    // minWidth: 700,
  },
});

export default function DataTable({ rows, removeFn, saveFn, setBulletins, loadMore }) {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [openDelete, setOpenDelete] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [bulletin, setBulletin] = useState({})
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickEdit = (v) => {
    setBulletin(v)
    setOpenEdit(true)
  };

  const handleClickDelete = (v) => {
    setBulletin(v)
    setOpenDelete(true)
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>번호</StyledTableCell>
              <StyledTableCell>제목</StyledTableCell>
              <StyledTableCell>Category</StyledTableCell>
              <StyledTableCell>Creator</StyledTableCell>
              <StyledTableCell>Date</StyledTableCell>
              <StyledTableCell align="right">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, idx) => (
              <StyledTableRow key={idx}>
                <StyledTableCell>
                  {row.num}
                </StyledTableCell>
                <StyledTableCell>
                  <span style={{cursor: "pointer"}} onClick={() => handleClickEdit(row)}>
                    {row.title}
                  </span>
                </StyledTableCell>
                <StyledTableCell>{row.category.join(',')}</StyledTableCell>
                <StyledTableCell>{row.creator}</StyledTableCell>
                <StyledTableCell>{moment(row.created_at).format('ll')}</StyledTableCell>
                <StyledTableCell align="right">
                  <EditIcon onClick={() => handleClickEdit(row)}/>&nbsp;&nbsp;
                  <DeleteIcon onClick={() => handleClickDelete(row)}/>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan="6">
                <Button label="더보기"
                  color="primary"
                  variant="contained"
                  onClick={() => loadMore()}
                  style={{width: "100%", margin: "1rem 0"}}>더보기</Button>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      {bulletin && openDelete && <Delete removeFn={removeFn} open={openDelete} setOpenDelete={setOpenDelete} bulletin={bulletin}/>}
      {bulletin && openEdit && <Edit saveFn={saveFn} open={openEdit} setOpenEdit={setOpenEdit} bulletin={bulletin} setBulletin={setBulletin}/>}
    </>
  );
}
