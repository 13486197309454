import React, { useState } from 'react';
import moment from 'moment';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import PropTypes from 'prop-types';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Edit from './PageManagementEdit';
import Delete from './PageManagementDelete';
import Add from './PageManagementAdd';
import { STATIC_SERVER } from '../../Config/constants';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#D5D5D5',
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const useStyles = makeStyles({
  table: {
    // minWidth: 700,
  },
});

export default function DataTable({ rows, removeFn, saveFn }) {
  const classes = useStyles();
  const [openDelete, setOpenDelete] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(null)

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickEdit = (v) => {
    setPage(v)
    setOpenEdit(true)
  };

  const handleClickDelete = (v) => {
    setPage(v)
    setOpenDelete(true)
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell width="15%">페이지</StyledTableCell>
              <StyledTableCell width="15%">URI</StyledTableCell>
              <StyledTableCell width="60%">Banner</StyledTableCell>
              <StyledTableCell align="right" width="10%">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, idx) => (
              <StyledTableRow key={idx}>
                <StyledTableCell component="th" scope="row">
                  {row.label}
                </StyledTableCell>
                <StyledTableCell>{row.uri}</StyledTableCell>
                <StyledTableCell style={{width: "50%"}}>
                  <img src={STATIC_SERVER+row.banner.img_path} style={{width: "100%"}}/>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <EditIcon onClick={() => handleClickEdit(row)}/>&nbsp;&nbsp;
                  <DeleteIcon onClick={() => handleClickDelete(row)}/>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {page && openDelete && <Delete removeFn={removeFn} open={openDelete} setOpenDelete={setOpenDelete} page={page}/>}
      {page && openEdit && <Edit saveFn={saveFn} open={openEdit} setOpenEdit={setOpenEdit} page={page} setPage={setPage}/>}
    </>
  );
}
