import React, { useState } from 'react';
import _ from 'lodash';
import querystring from 'querystring';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import Header from '../../Components/Header';
import { useAuthDispatch, logout, useAuthState } from '../../Context';
import styles from './chronicle.module.scss';
import { useHistory } from "react-router-dom";
import PhotoCard from "../Photo/PhotoCard";
import { API_SERVER } from '../../Config/constants';
import { Button, TextField } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Add from './ChronicleAdd';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import DataTable from './DataTable';

function Chronicle(props) {
	const dispatch = useAuthDispatch();
	const userDetails = useAuthState();
	const history = useHistory()
	const [newPage, setNewPage] = useState(false)
  const [evts, setEvts] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear());
	const addPage = () => {
		setNewPage(true)
	}
	const removeFn = id => {
		setEvts(evts.filter(v => v._id !== id))
	}
	const saveFn = evt => {
		evts.forEach(el => {
			if (el._id === evt._id) {
				Object.assign(el, evt)
			}
		})
		setEvts(evts)
	}
	const years = []
	const now = new Date()
	for (let i=2000; i<=now.getFullYear(); i++) {
		years.push(i)
	}
	const handleYearChange = (year) => {
		setYear(year)
		getData(year)
	}

	const getData = async (year) => {
		const { data } = await axios.get(`${API_SERVER}/api/chronicle?${querystring.encode({year, limit: 200, sort: 'start', order: 'asc'})}`) // show all
		setEvts(data || [])
	}

  React.useEffect(() => {
		getData(now.getFullYear())
  }, [])

	return (
		<>
			<Header/>
			<div className={styles.container}>
				<div className={styles.searchField}>
					<FormControl className={styles.formControl}>
						<InputLabel id="chronicle-year">연도</InputLabel>
						<Select
							value={year}
							labelId="chronicle-year"
							onChange={(e) => handleYearChange(e.target.value)}
						>
						{years.map(y => <MenuItem value={y}>{y}</MenuItem>)}
						</Select>
					</FormControl>
					<div>
						<Button size="large" onClick={addPage} variant="contained" style={{verticalAlign: 'bottom'}}>
							<AddIcon/>
						</Button>
					</div>
				</div>
				<DataTable rows={evts} removeFn={removeFn} saveFn={saveFn} setEvts={setEvts}/>
			</div>
			{newPage && <Add open={newPage} setOpen={setNewPage} />}
		</>
	);
}

export default Chronicle;
