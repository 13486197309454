import React, { useEffect, useState } from 'react';
import { API_SERVER } from '../../Config/constants';
import axios from 'axios';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { TextField } from '@material-ui/core';
import styles from './video.module.scss';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { useAuthState } from '../../Context';

export default function Add({ open, setOpenAdd, video={} }) {
  const [ videoId, setVideoId ] = useState('')
  const [ videoTags, setVideoTags ] = useState('')
  const [ videoDescription, setVideoDescription ] = useState('')
  const [ videoTitle, setVideoTitle ] = useState('')
  const [ videoCategory, setVideoCategory ] = useState('')
  const [ videoInfo, setVideoInfo ] = useState(null)
  const handleClose = () => {
    setOpenAdd(false)
  }
  const userDetails = useAuthState()
  const handleAdd = async () => {
    const res = await axios.post(`${API_SERVER}/api/video`, {
      title: videoTitle,
      source: "youtube",
      video_id: videoId,
      description: videoDescription,
      tags: videoTags.replace(/\s/g, ''),
      category: videoCategory,
      description: videoDescription,
      creator: userDetails.user.email,
      img_small: `https://i.ytimg.com/vi/${videoId}/mqdefault.jpg`,
      img_large: `https://i.ytimg.com/vi/${videoId}/hqdefault.jpg`
    })
    setOpenAdd(false)
  }
  const handleGet = async () => {
    const { data } = await axios.get(`${API_SERVER}/api/video/youtube/${videoId}`)
    setVideoInfo(data.data)
    setVideoTitle(data.data.title)
    setVideoDescription(data.data.description)
    setVideoTags(data.data.tags)
  }
  return (
    <>
      <Dialog
        open={open} maxWidth="sm" fullWidth={true}
        onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">비디오 추가</DialogTitle>
        <DialogContent>
          <div style={{
            display: "flex",
            justifyContent: "space-between"
          }}>
						<TextField label="Video ID" margin="dense" value={videoId} variant="outlined"
              style={{width: "70%"}}
              onChange={(e) => setVideoId(e.target.value)}
            />
            <Button onClick={handleGet} color="primary" size="small" style={{marginTop: "10px"}}>
              가져오기
            </Button>
          </div>
          { videoInfo &&
            <div>
              <TextField label="제목" margin="dense" value={videoTitle} variant="outlined"
                style={{width: "100%"}}
                onChange={(e) => setVideoTitle(e.target.value)}
              />
              <FormControl style={{width: "100%"}}>
                <InputLabel id="video-add-category">타입</InputLabel>
                <Select
                  labelId="video-add-category"
                  onChange={(e) => setVideoCategory(e.target.value)}
                >
                  <MenuItem value='전체'>전체</MenuItem>
                  <MenuItem value='설교'>설교</MenuItem>
                  <MenuItem value='예배실황'>예배실황</MenuItem>
                  <MenuItem value='매일말씀묵상'>매일말씀묵상</MenuItem>
                  <MenuItem value='간증'>간증</MenuItem>
                  <MenuItem value='경배와찬양'>경배와찬양</MenuItem>
                  <MenuItem value='성가대'>성가대</MenuItem>
                  <MenuItem value='특송'>특송</MenuItem>
                  <MenuItem value='행사'>행사</MenuItem>
                </Select>
              </FormControl>
              <TextField label="TAGS" margin="dense" value={videoTags} variant="outlined"
                style={{width: "100%"}}
                onChange={(e) => setVideoTags(e.target.value)}
              />
              <TextField label="Description" margin="dense" value={videoDescription} variant="outlined"
                style={{width: "100%"}}
                onChange={(e) => setVideoDescription(e.target.value)}
              />
              <a href={`http://youtube.com/watch?v=${videoId}`} target="_blank">
                <img src={videoInfo.img_small} width="100%"/>
              </a>
            </div>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            취소
          </Button>
          <Button onClick={handleAdd} color="primary">
            저장
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
