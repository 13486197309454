import React from 'react';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import YouTubeIcon from '@material-ui/icons/YouTube';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import EventNoteIcon from '@material-ui/icons/EventNote';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import MenuBookIcon from '@material-ui/icons/MenuBook';

import Video from '../../Pages/Video';
import Photo from '../../Pages/Photo';
import Banner from '../../Pages/Banner';
import Bulletin from '../../Pages/Bulletin';
import Jubo from '../../Pages/Jubo';
import PageManagement from '../../Pages/PageManagement';
import Chronicle from '../../Pages/Chronicle';
import Staff from '../../Pages/Staff';

const MenuData = [
  {
    name: "비디오",
    path: "/video",
    icon: <YouTubeIcon/>,
    component: Video,
    isPrivate: true
  }, {
    name: "사진",
    path: "/photo",
    icon: <PhotoCameraIcon/>,
    component: Photo,
    isPrivate: true
  }, {
    name: "배너",
    path: "/banner",
    icon: <ViewCarouselIcon/>,
    component: Banner,
    isPrivate: true
  }, {
    name: "게시판",
    path: "/bulletin",
    icon: <FormatListBulletedIcon/>,
    component: Bulletin,
    isPrivate: true
  }, {
    name: "주보",
    path: "/jubo",
    icon: <MenuBookIcon/>,
    component: Jubo,
    isPrivate: true
  }, {
    name: "페이지관리",
    path: "/pagemanagement",
    icon: <AccountBalanceIcon/>,
    component: PageManagement,
    isPrivate: true
  }, {
    name: "연혁",
    path: "/chronicle",
    icon: <EventNoteIcon/>,
    component: Chronicle,
    isPrivate: true
  }, {
    name: "섬기는이",
    path: "/staff",
    icon: <AccountBoxIcon/>,
    component: Staff,
    isPrivate: true
  }
];

export { MenuData };