import React, { useState } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import EventIcon from '@material-ui/icons/Event';
import SettingsIcon from '@material-ui/icons/Settings';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { LensTwoTone } from '@material-ui/icons';
import Delete from './BannerDelete';
import Edit from './BannerEdit';
import { STATIC_SERVER } from '../../Config/constants';

export default function BannerCard({ banner={}, setBanner, removeFn }) {

  const handleClickDelete = () => {
    setOpenDelete(true);
  }
  const handleClickEdit = () => {
    setOpenEdit(true);
  }
	const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  return (
    <>
      <Card variant="outlined">
        <CardMedia
          component="img"
          alt={banner.title}
          image={`${STATIC_SERVER}${banner.img_path}`}
          title={banner.title}
        />
        <CardContent style={{
          padding: "0.5rem 1.2rem"
        }}>
          <Typography gutterBottom variant="subtitle1" component="h6">
            {banner.title}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {banner.sub_title}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {banner.link_name}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {banner.link}
          </Typography>
          <Typography variant="subtitle2" color="textSecondary"
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "10px"
            }}>
            <div>
              <EventIcon style={{fontSize:"24px", marginRight:"5px"}}/>
              <div style={{
                display:"inline-block",
                verticalAlign: "top",
                paddingTop: "3px"
              }}>
                {moment(banner.created_at).format('ll')} by {banner.creator}
              </div>
            </div>
            <div style={{
                display:"inline-block",
                verticalAlign: "top",
                paddingTop: "3px",
                letterSpacing: 10,
                cursor: "pointer"
              }}>
                <EditIcon onClick={handleClickEdit}/>&nbsp;
                <DeleteIcon onClick={handleClickDelete}/>
            </div>
          </Typography>
        </CardContent>
      </Card>
      <Delete open={openDelete} setOpenDelete={setOpenDelete} banner={banner} removeFn={removeFn}/>
      <Edit open={openEdit} setOpenEdit={setOpenEdit} banner={banner} setBanner={setBanner}/>
    </>
  );
}
