import React, { useState } from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid, Table, TableCell, TableBody, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import { API_SERVER } from '../../Config/constants';
import { useAuthState } from '../../Context';

export default function Add({ open, setOpen, content, title }) {

  const [files, setFiles] = useState([])
  const [allTitle, setAllTitle] = useState('')
  const [allTags, setAllTags] = useState('')
  const [loadingIndex, setLoadingIndex] = useState(null)
	const userDetails = useAuthState()

  const handleClose = () => {
    setOpen(false)
  }
  const handleSave = () => {

  }
  const handleDelete = idx => {
    setFiles(files.filter((v, i) => i !== idx))
  }
  const handleApplyAll = () => {
    Array
      .from(document.querySelectorAll("input[name='image-title']"))
      .forEach(v => v.value = allTitle)
    Array
      .from(document.querySelectorAll("input[name='image-tags']"))
      .forEach(v => v.value = allTags)
  }

  const uploadMultipleFiles = (e) => {
    const list = []
    const len = e.target.files.length
    for (let i = 0; i < len; i++) {
      let file = e.target.files[i]
      let reader = new FileReader()
      reader.addEventListener("load", function (event) {
        let img = new Image()
        img.src = this.result
        img.name = file.name
        img.file = file
        img.index = i
        img.onload = () => {
          list.push(img)
          URL.revokeObjectURL(img.src)
          if (list.length === len) {
            setFiles(list)
          }
        };
      }, false)
      reader.readAsDataURL(file)
    }
  }

  const handleUpload = async () => {
    const images = [...files]
    const upload = async () => {
      if (images.length) {
        const image = images.shift()
        setLoadingIndex(image.index)
        const titleElement = document.querySelector(`#upload-image-title-${image.index}`)
        const tagsElement = document.querySelector(`#upload-image-tags-${image.index}`)

        const formData = new FormData();
        formData.append('file', image.file)
        formData.append('file_name', image.name)
        formData.append('width', image.width)
        formData.append('height', image.height)
        formData.append('tags', tagsElement.value)
        formData.append('title', titleElement.value || image.name)
        formData.append('creator', userDetails.user.email)
        const res = await axios({
          method: 'post',
          url: `${API_SERVER}/api/photo/addraw`,
          data: formData,
          headers: { "Content-Type": "multipart/form-data" }
        })
        setLoadingIndex(-1)
        upload()
      } else {
        setFiles([])
        handleClose(true)
        window.location.reload()
      }
    }
    upload()
  }

  return (
      <Dialog
        maxWidth="xl"
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          사진 추가 ({files.length})
          <div>한번에 여러개 가능 (JPG/PNG) </div>
        </DialogTitle>
        <DialogContent align="center">
          <form>
            <TableContainer component={Paper}>
              <Table>
                { files && files.length &&
                <TableHead>
                  <TableRow>
                    <TableCell style={{width:"35%"}}>
                      <TextField
                        name="Title"
                        margin="dense"
                        label="사진제목(for all)"
                        type="text"
                        onChange={(e) => setAllTitle(e.target.value)}
                        value={allTitle}
                        variant="outlined"
                      />
                      <Button onClick={handleApplyAll} color="primary" style={{marginTop: "10px"}}>
                        적용
                      </Button>
                      <br/>
                      <TextField
                        name="Tags"
                        margin="dense"
                        label="사진태그(for all)"
                        type="text"
                        onChange={(e) => setAllTags(e.target.value)}
                        value={allTags}
                        variant="outlined"
                      />
                    </TableCell>
                    <TableCell>Photo</TableCell>
                    <TableCell size="small" style={{width:"10%"}}>Control</TableCell>
                  </TableRow>
                </TableHead>
                }
                <TableBody>
                  {files.map((img, idx) => (
                    <TableRow key={idx}>
                      <TableCell component="th" scope="row" style={{width:"30%"}}>
                        <TextField margin="dense" variant="outlined" label="제목" name="image-title" id={'upload-image-title-'+idx}/><br/>
                        <TextField margin="dense" variant="outlined" label="Tags" name="image-tags" id={'upload-image-tags-'+idx}/>
                      </TableCell>
                      <TableCell>
                        너비: {img.width}px 높이:{img.height}px<br/>
                        <img name="image-obj" src={img.src} alt="..." height="200" width="auto"/>
                      </TableCell>
                      <TableCell size="small" style={{width:"10%"}}>
                        {loadingIndex === idx && <CircularProgress color="primary" />}
                        <Button onClick={() => handleDelete(idx)} color="secondary">
                          삭제
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div>
                <input style={{
                  marginTop: "10px",
                  fontSize: "1rem",
                  backgroundColor: "#e3e3e3",
                  padding: "1rem"
                }} accept="image/jpeg, image/jpg" type="file" className="form-control" onChange={uploadMultipleFiles} multiple />
            </div>
          </form >
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            취소
          </Button>
          <Button onClick={handleUpload} color="primary">
            업로드
          </Button>
        </DialogActions>
      </Dialog>
  );
}
