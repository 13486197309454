import React, { useEffect, useState } from 'react';
import moment from 'moment';
import axios from 'axios';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Edit from './StaffEdit';
import Delete from './StaffDelete';
import { API_SERVER, STATIC_SERVER } from '../../Config/constants';
import tableDragger from 'table-dragger'

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#D5D5D5',
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    // minWidth: 700,
  },
});

export default function DataTable({ rows=[], removeFn, saveFn, setStaffs }) {
  const classes = useStyles();
  const [openDelete, setOpenDelete] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [staff, setStaff] = useState()

  const handleClickEdit = (v) => {
    console.log(v)
    setStaff(v)
    setOpenEdit(true)
  };

  const handleClickDelete = (v) => {
    setStaff(v)
    setOpenDelete(true)
  };

  useEffect(() => {
    const table = document.getElementById('staffs-table')
    const dragger = tableDragger(table, {
      mode: 'row',
      // dragHandler: 'td',
      onlyBody: true,
      animation: 300
    })
    dragger.on('drop', async (from, to) => {
      const ids = []
      document.querySelectorAll('tr[_id]').forEach(v => ids.push(v.getAttribute('_id')))
      const res = await axios.put(`${API_SERVER}/api/staff/sort`, {ids})
      setOpenEdit(false)
    });

  })

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table" id="staffs-table">
          <TableHead>
            <TableRow>
              <StyledTableCell>이름 (드래그 하여 순서변경)</StyledTableCell>
              <StyledTableCell>직분</StyledTableCell>
              <StyledTableCell>타이틀</StyledTableCell>
              <StyledTableCell>이미지</StyledTableCell>
              <StyledTableCell align="right" width="10%">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <StyledTableRow key={row._id} _id={row._id}>
                <StyledTableCell scope="row" className="handle">
                  {row.name}
                </StyledTableCell>
                <StyledTableCell className="handle">
                  {row.role}
                </StyledTableCell>
                <StyledTableCell className="handle">
                  {row.status}
                </StyledTableCell>
                <StyledTableCell className="handle">
                  {row.image && <a href={STATIC_SERVER+row.image.img_path} target="_blank"><img src={STATIC_SERVER+row.image.img_path} style={{height:"60px"}}/></a>}
                  {row.old && <a href={STATIC_SERVER+row.old.image} target="_blank"><img src={STATIC_SERVER+row.old.image} style={{height:"60px"}}/></a>}
                  &nbsp;
                  {row.back && <a href={STATIC_SERVER+row.back.img_path} target="_blank"><img src={STATIC_SERVER+row.back.img_path} style={{height:"60px"}}/></a>}
                  {row.old && row.old.back && <a href={STATIC_SERVER+row.old.back} target="_blank"><img src={STATIC_SERVER+row.old.back} style={{height:"60px"}}/></a>}
                </StyledTableCell>
                <StyledTableCell align="right">
                  <EditIcon onClick={() => handleClickEdit(row)}/>&nbsp;&nbsp;
                  <DeleteIcon onClick={() => handleClickDelete(row)}/>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {staff && <Delete removeFn={removeFn} open={openDelete} setOpenDelete={setOpenDelete} staff={staff}/>}
      {staff && <Edit saveFn={saveFn} open={openEdit} setOpenEdit={setOpenEdit} staff={staff} setStaff={setStaff}/>}
    </>
  );
}
