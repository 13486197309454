import React, { useState } from 'react';
import _ from 'lodash';
import axios from 'axios';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { API_SERVER } from '../../Config/constants';

export default function Edit({ open, video={}, setOpenEdit, saveFn }) {
  const [ title, setTitle ] = useState(video.title)
  const [ description, setDescription ] = useState(video.description)
  const [ category, setCategory ] = useState(video.category)
  const [ tags, setTags ] = useState(video.tags)
  const handleSave = async () => {
    const param = { title, description, tags, id: video._id, category }
    const res = await axios.put(`${API_SERVER}/api/video/${video._id}`, param)
    Object.assign(video, param)
    setOpenEdit(false)
  }
  const handleClose = () => {
    setOpenEdit(false)
  }

  return (
    <>
      <Dialog maxWidth="sm" fullWidth={true} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Video 수정</DialogTitle>
        <DialogContent>
          <TextField
            name="title"
            margin="dense"
            label="제목"
            type="text"
            onChange={(e) => setTitle(e.target.value)}
            fullWidth
            value={title}
            variant="outlined"
          />
          <FormControl style={{width: "100%"}}>
            <InputLabel id="video-edit-category">타입</InputLabel>
            <Select
              labelId="video-edit-category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              <MenuItem value='전체'>전체</MenuItem>
              <MenuItem value='설교'>설교</MenuItem>
              <MenuItem value='예배실황'>예배실황</MenuItem>
              <MenuItem value='매일말씀묵상'>매일말씀묵상</MenuItem>
              <MenuItem value='간증'>간증</MenuItem>
              <MenuItem value='경배와찬양'>경배와찬양</MenuItem>
              <MenuItem value='성가대'>성가대</MenuItem>
              <MenuItem value='특송'>특송</MenuItem>
              <MenuItem value='행사'>행사</MenuItem>
            </Select>
          </FormControl>
          <TextField
            name="tags"
            margin="dense"
            label="태그"
            type="text"
            onChange={(e) => setTags(e.target.value)}
            fullWidth
            value={tags}
            variant="outlined"
          />
          <TextField
            name="description"
            margin="dense"
            label="설명"
            type="text"
            onChange={(e) => setDescription(e.target.value)}
            fullWidth
            value={description}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            취소
          </Button>
          <Button onClick={handleSave} color="primary">
            저장
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
