import React, { useState } from 'react';
import { convertFromRaw, EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs'

export default function HtmlEditor({ onChange, html }) {
  let prevState, contentState, contentBlock
  if (html) {
    contentBlock = htmlToDraft(html)
    contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
    prevState = EditorState.createWithContent(contentState)
  }
  const [newState, setNewState] = useState(html ? prevState : EditorState.createEmpty())
  const handleEditorStateChange = (editorState) => {
    setNewState(editorState || EditorState.createEmpty())
    console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())))
    onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())))
  }

  return (
    <>
      <Editor
        editorState={newState}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={handleEditorStateChange}
        toolbar={{
          inline: { inDropdown: true },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          link: { inDropdown: true },
          history: { inDropdown: true },
          image: { defaultSize: { width: '100%'}}
        }}
      />
    </>
  )
}