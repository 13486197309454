import React, { useState } from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid, Table, TableCell, TableBody, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import { API_SERVER } from '../../Config/constants';
import Slider from '@material-ui/core/Slider';
import { useAuthState } from '../../Context';
import DatePicker from '../../Components/DatePicker';

export default function Add({ open, setOpen }) {

	const userDetails = useAuthState()
  const nextSunday = () => {
    const [m, d, y] = new Date().toLocaleDateString().split('/')
    return `${y}-${m<10 ? '0'+m: m}-${d<10 ? '0'+d: d}`
  }
  const [date, setDate] = useState(nextSunday())
  const handleClose = () => {
    setOpen(false)
  }
  const handleUpload = async () => {
    const formData = new FormData()
    const file = document.querySelector('#jubo-file')
    formData.append("file", file.files[0])
    formData.append('creator', userDetails.user.email)
    let res = await axios.post(`${API_SERVER}/api/file/add`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    const { file_path, file_name, host } = res.data.data
    res = await axios.post(`${API_SERVER}/api/jubo`, {
      date, file_path, file_name, host, creator: userDetails.user.email
    })
    window.location.reload()
  }

  return (
    <div>
      <Dialog
        maxWidth="sm"
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
           주보 추가 (PDF 파일만 가능)
        </DialogTitle>
        <DialogContent align="center">
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <DatePicker label="날짜 선택" defaultDate={nextSunday()} onDateChange={setDate}/>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <input style={{
                      width: "calc(100% - 2rem)",
                      fontSize: "1rem",
                      backgroundColor: "#e3e3e3",
                      padding: "1rem"
                    }} accept="application/pdf" id="jubo-file" type="file" className="form-control"/>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            취소
          </Button>
          <Button onClick={handleUpload} color="primary">
            업로드
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
