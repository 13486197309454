import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";

import { loginUser, useAuthState, useAuthDispatch } from '../../Context';
import styles from './login.module.css';

function Login(props) {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const history = useHistory();
	const dispatch = useAuthDispatch();
	const { loading, errorMessage } = useAuthState();

	const handleLogin = async (e) => {
		e.preventDefault();
		try {
			let response = await loginUser(dispatch, { email, password });
			if (!response.user) return;
			history.push('/video');
		} catch (error) {
			console.log(error);
		}
	};
	const handleKeyUp = event => {
		if (event.keyCode === 13) {
			handleLogin(event);
		}
	}

	return (
		<>
			<div className={styles.header}>
				오스틴 주님의 교회 Admin
			</div>
			<div className={styles.container}>
					<form onKeyUp={(e) => handleKeyUp(e)}>
						<div className={styles.loginForm}>
							<TextField
								id="outlined-email"
								label="Email"
								variant="outlined"
								onChange={(e) => setEmail(e.target.value)}
							/>
							<hr/>
							<TextField
								id="standard-password-input"
								label="Password"
								type="password"
								autoComplete="current-password"
								variant="outlined"
								onChange={(e) => setPassword(e.target.value)}
							/>
							<hr/>
							<Button variant="contained" color="primary" onClick={handleLogin} >
								Login
							</Button>
							{errorMessage ? <p className={styles.error}>{errorMessage}</p> : null}
						</div>
					</form>
			</div>
		</>
	);
}

export default Login;
