import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";

import { loginUser, signupUser, useAuthState, useAuthDispatch } from '../../Context';
import styles from './login.module.css';

function Signup(props) {
	const [firstname, setFirstname] = useState('');
	const [lastname, setLastname] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const history = useHistory();
	const dispatch = useAuthDispatch();
	const { loading, errorMessage } = useAuthState();

	const handleSignup = async (e) => {
		e.preventDefault();

		try {
			let response = await signupUser(dispatch, { firstname, lastname, email, password });
			if (!response.user) return;
			history.push('/video');
		} catch (error) {
			console.log(error);
		}
	};
	const handleKeyUp = event => {
		if (event.keyCode === 13) {
			handleSignup(event);
		}
	}

	return (
		<>
			<div className={styles.header}>
				오스틴 주님의 교회 Admin
			</div>
			<div className={styles.container}>
					<form onKeyUp={(e) => handleKeyUp(e)}>
						<div className={styles.loginForm}>
						<TextField
								id="outlined-firstname"
								label="First name"
								variant="outlined"
								onChange={(e) => setFirstname(e.target.value)}
							/>
							<hr/>
							<TextField
								id="outlined-lastname"
								label="Last name"
								variant="outlined"
								onChange={(e) => setLastname(e.target.value)}
							/>
							<hr/>
							<TextField
								id="outlined-email"
								label="Email"
								variant="outlined"
								onChange={(e) => setEmail(e.target.value)}
							/>
							<hr/>
							<TextField
								id="standard-password-input"
								label="Password"
								type="password"
								autoComplete="current-password"
								variant="outlined"
								onChange={(e) => setPassword(e.target.value)}
							/>
							<hr/>
							<Button variant="contained" color="primary" onClick={handleSignup} >
								Signup
							</Button>
							{errorMessage ? <p className={styles.error}>{errorMessage}</p> : null}
						</div>
					</form>
			</div>
		</>
	);
}

export default Signup;
