import React, { useState } from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid, Table, TableCell, TableBody, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import { API_SERVER } from '../../Config/constants';
import HtmlEditor from '../../Components/HtmlEditor';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { useAuthState } from '../../Context';
import FileCopyIcon from '@material-ui/icons/FileCopy';

export default function Add({ open, setOpen, bulletin={} }) {
  const [image, setImage] = useState(null)
  const [imageUrl, setImageUrl] = useState('')
  const [imageList, setImageList] = useState([])
  const [title, setTitle] = useState('')
  const [loading, setLoading] = useState(false)
  const [editorState, setEditorState] = useState()
  const [category, setCategory] = useState([])
  const userDetails = useAuthState()
  const [createdAt, setCreatedAt] = useState(new Date().toISOString().slice(0, 10))

  const handleClose = () => {
    setOpen(false)
  }

  const uploadFile = (e) => {
    const list = []
    let file = e.target.files[0]
    let reader = new FileReader()
    reader.addEventListener("load", function () {
      let img = new Image()
      img.src = this.result
      img.onload = async () => {
        setImage(img)
        const formData = new FormData()
        formData.append('file', img.src)
        formData.append('width', img.width)
        formData.append('height', img.height)
        formData.append('file_name', file.name)
        formData.append('creator', userDetails.user.email)
        const { data } = await axios({
          method: 'post',
          url: `${API_SERVER}/api/image/add`,
          data: formData,
          headers: { "Content-Type": "multipart/form-data" }
        })
        setImageUrl(`${API_SERVER}${data.data.img_path}`)
      };
    }, false)

    reader.readAsDataURL(file)
  }

  const handleCategory = (event) => {
    category[event.target.name] = event.target.checked
    setCategory(category)
  }

  const handleSave = async () => {
      setLoading(true)
      const { data } = await axios({
        method: 'post',
        url: `${API_SERVER}/api/bulletin`,
        data: {
          title,
          category: Object.keys(category).filter(v => category[v] === true),
          body: editorState,
          created_at: createdAt,
          creator: `${userDetails.user.firstname || ''} ${userDetails.user.lastname || ''}`
        }
      })
      window.location.reload()
  }

  const copyToClipboard = () => {
    var urlField = document.querySelector('#bulletin-img-url');
    urlField.focus();
    urlField.select();

    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      console.log('Copying text command was ' + msg);
    } catch (err) {
      console.log('Oops, unable to copy');
    }
  }
  const categories = ['메인화면','영아부','유아부','초등부','중고등부','대학부','EM','청년부','에노스','커피브레이크','신구약맥잡기','크라운재정교실', '가온토요학교','선교지']

  return (
    <div>
      <Dialog
        maxWidth="xl"
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
           게시물 추가
        </DialogTitle>
        <DialogContent align="center">
          <form>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                      <TableCell component="th" scope="row">
                        {categories.map(c => (
                          <FormControlLabel key={c}
                            control={ <Checkbox onChange={(e) => handleCategory(e)} name={c} color="primary" /> }
                            label={c}
                          />
                        ))}
                      </TableCell>
                    </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <TextField margin="dense" variant="outlined" label="Title" name="image-title" onChange={(e) => setTitle(e.target.value)} style={{width:"60%"}}/>
                      <TextField type="date" margin="dense" defaultValue={createdAt} required variant="outlined" label="게시일" name="start" onChange={(e) => setCreatedAt(e.target.value)} style={{width:"30%", marginLeft: "5%"}}/>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <HtmlEditor editorState={editorState} onChange={setEditorState}/>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <input style={{
                        width: "calc(100% - 2rem)",
                        fontSize: "1rem",
                        backgroundColor: "#e3e3e3",
                        padding: "1rem"
                      }} accept="image/*"  type="file" className="form-control" onChange={uploadFile}/>
                    </TableCell>
                  </TableRow>
                  {image &&
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <TextField id="bulletin-img-url" label="Image URL" size="small" variant="outlined" value={imageUrl} style={{width: "calc(100% - 70px)"}}/>
                      <FileCopyIcon onClick={copyToClipboard} style={{cursor: "pointer", width: "2.4rem", height: "2.4rem", marginLeft: "20px"}}/>
                    </TableCell>
                  </TableRow>
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </form >
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            취소
          </Button>
          <Button onClick={handleSave} color="primary">
            저장
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
