import React, { useState } from 'react';
import _ from 'lodash';
import axios from 'axios';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { API_SERVER } from '../../Config/constants';

export default function Edit({ open, photo={}, setOpenEdit, saveFn }) {
  const [ title, setTitle ] = useState(photo.title)
  const [ description, setDescription ] = useState(photo.description)
  const [ tags, setTags ] = useState(photo.tags)
  const handleSave = async () => {
    const param = { title, description, tags }
    Object.assign(photo, param)
    const res = await axios.put(`${API_SERVER}/api/photo/${photo._id}`, param)
    setOpenEdit(false)
  }
  const handleClose = () => {
    setOpenEdit(false)
  }

  return (
    <>
      <Dialog maxWidth="sm" fullWidth={true} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Photo 수정</DialogTitle>
        <DialogContent>
          <TextField
            name="title"
            margin="dense"
            label="제목"
            type="text"
            onChange={(e) => setTitle(e.target.value)}
            fullWidth
            value={title}
            variant="outlined"
          />
          <TextField
            name="description"
            margin="dense"
            label="설명"
            type="text"
            onChange={(e) => setDescription(e.target.value)}
            fullWidth
            value={description}
            variant="outlined"
          />
          <TextField
            name="tags"
            margin="dense"
            label="태그"
            type="text"
            onChange={(e) => setTags(e.target.value)}
            fullWidth
            value={tags}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            취소
          </Button>
          <Button onClick={handleSave} color="primary">
            저장
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
