import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import Header from '../../Components/Header';
import { useAuthDispatch, logout, useAuthState } from '../../Context';
import styles from './photo.module.scss';
import { useHistory } from "react-router-dom";
import PhotoCard from "./PhotoCard";
import { API_SERVER } from '../../Config/constants';
import { Button, TextField } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import _ from 'lodash';
import querystring from 'querystring';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Add from './PhotoAdd';
import Delete from './PhotoDelete';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import { now } from 'moment';

function Photo(props) {
	const dispatch = useAuthDispatch();
	const userDetails = useAuthState();
	const history = useHistory()
	const [count, setCount] = useState(0)
	const [newPhoto, setNewPhoto] = useState(false)
	const [keyword, setKeyword] = useState('')
	const [category, setCategory] = useState('');
  const [photos, setPhotos] = useState([]);
	const [paging, setPaging] = useState({skip: 0, limit: 20})

	const searchPhoto = async (reset=false) => {
		if (reset) {
			setCount(0)
			paging.skip = 0
			setPaging(paging)
			setPhotos([])
		}
		const params = {
			skip: reset ? 0 : (count || paging.skip),
			limit: paging.limit
		}
		if (keyword) {
			params.title = keyword;
		}
		if (category) {
			params.category = category;
		}
		const { data } = await axios.get(`${API_SERVER}/api/photo?${querystring.encode(params)}`);
		if (data && data.length > 0) {
			setCount(count + data.length)
			setPhotos([...photos, ...data])
		}
	}
  const handleCategoryChange = (event) => {
    setCategory(event.target.value)
  }

	const viewMore = num => {
		paging.skip = count
		paging.limit = num
		setPaging(paging)
		searchPhoto()
	}
	const addPhoto = () => {
		setNewPhoto(true)
	}
	const removeFn = id => {
		setPhotos(photos.filter(v => v._id !== id))
	}

  React.useEffect(() => {
		searchPhoto()
  }, [])
	return (
		<>
			<Header/>
			<div className={styles.container}>
				<div className={styles.searchField}>
					<FormControl className={styles.formControl}>
						<InputLabel id="photo-category-search">사진 타입</InputLabel>
						<Select
							labelId="photo-category-search"
							onChange={handleCategoryChange}
						>
							<MenuItem value='전체'>전체</MenuItem>
							<MenuItem value='설교'>설교</MenuItem>
							<MenuItem value='예배실황'>예배실황</MenuItem>
							<MenuItem value='매일말씀묵상'>매일말씀묵상</MenuItem>
							<MenuItem value='간증'>간증</MenuItem>
							<MenuItem value='경배와찬양'>경배와찬양</MenuItem>
							<MenuItem value='성가대'>성가대</MenuItem>
							<MenuItem value='특송'>특송</MenuItem>
							<MenuItem value='행사'>행사</MenuItem>
						</Select>
					</FormControl>
					<div>
						<TextField id="photo-search-keyword" label="검색어"
							onChange={(event) => setKeyword(event.target.value)} />
						<Button size="large" onClick={searchPhoto} variant="contained" style={{verticalAlign: 'bottom'}}>
							<SearchIcon/>
						</Button>
						&nbsp; &nbsp;
						<Button size="large" onClick={addPhoto} variant="contained" style={{verticalAlign: 'bottom'}}>
							<AddIcon/>
						</Button>
					</div>
				</div>
				<Grid container spacing={1} style={{justifyContent: "space-around"}}>
					<Grid container item xs={12} spacing={3}>
						{photos.map((photo, index) => (
							<Grid item xs={12} sm={6} md={4} lg={4} xl={3} key={index}>
								<PhotoCard key={index} photo={photo} removeFn={removeFn}/>
							</Grid>
						))}
						<Grid item xs={12} sm={6} md={4} lg={4} xl={3} key={photos.length} align="center">
							<Card variant="outlined"
								style={{
									height: "334px",
									display: "flex",
									flexDirection: "column",
									justifyContent: "center"
								}}
							>
								<CardContent>
									<Typography variant="h6" component="h2">
										Total {photos.length} items
									</Typography>
									<br/>
									<Typography color="textSecondary" gutterBottom>
										<Button variant="contained" size="large"
											onClick={() => viewMore(10)}
										>
											10 more
										</Button>
										&nbsp;
										<Button variant="contained" size="large"
											onClick={() => viewMore(20)}
										>
											20 more
										</Button>
									</Typography>
								</CardContent>
							</Card>
						</Grid>
					</Grid>
				</Grid>
			</div>
			{newPhoto && <Add open={newPhoto} setOpen={setNewPhoto}/>}
		</>
	);
}

export default Photo;
