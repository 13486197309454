import React, { useState } from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Table, TableCell, TableBody, TableContainer, TableHead, TableRow, TextField, MenuItem, FormControl, InputLabel, Select } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { API_SERVER } from '../../Config/constants';
import { useAuthState } from '../../Context';

export default function Add({ open, setOpen }) {

  const [title, setTitle] = useState('')
  const [start, setStart] = useState(new Date().toISOString().slice(0, 10))
  const [end, setEnd] = useState()
  const [loading, setLoading] = useState(false)
  const userDetails = useAuthState()

  const handleClose = () => {
    setOpen(false)
  }

  const handleSave = async () => {
      setLoading(true)
      const { data } = await axios({
        method: 'post',
        url: `${API_SERVER}/api/chronicle`,
        data: { title, start, end, year: start.slice(0, 4) }
      })
      window.location.reload()
  }

  return (
    <div>
      <Dialog
        maxWidth="md"
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
           연혁 추가
        </DialogTitle>
        <DialogContent align="center">
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <TextField margin="dense" variant="outlined" defaultValue={title} required label="제목 (예: 새신자 환영회)" name="title" onChange={(e) => setTitle(e.target.value)} style={{width:"100%"}}/>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <TextField type="date" margin="dense" defaultValue={start} required variant="outlined" label="시작일 (필수)" name="start" onChange={(e) => setStart(e.target.value)} style={{width:"100%"}}/>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <TextField type="date" margin="dense" InputLabelProps={{ shrink: true }} variant="outlined" label="종료일 (선택)" name="end" onChange={(e) => setEnd(e.target.value)} style={{width:"100%"}}/>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            취소
          </Button>
          <Button onClick={handleSave} color="primary">
            저장
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
