import React, { useState } from 'react';
import _ from 'lodash';
import querystring from 'querystring';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import Header from '../../Components/Header';
import { useAuthDispatch, logout, useAuthState } from '../../Context';
import styles from './pagemanagement.module.scss';
import { useHistory } from "react-router-dom";
import PhotoCard from "../Photo/PhotoCard";
import { API_SERVER } from '../../Config/constants';
import { Button, TextField } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Add from './PageManagementAdd';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import DataTable from './DataTable';

function PageManagement(props) {
	const dispatch = useAuthDispatch();
	const userDetails = useAuthState();
	const history = useHistory()
	const [newPage, setNewPage] = useState(false)
  const [pages, setPages] = useState([]);
	const addPage = () => {
		setNewPage(true)
	}
	const removeFn = id => {
		setPages(pages.filter(v => v._id !== id))
	}
	const saveFn = page => {
		// setPages(pages.filter(v => v._id !== id))
	}
  React.useEffect(() => {
		axios.get(`${API_SERVER}/api/pagemanagement?${querystring.encode({limit: 100})}`)
		.then(({data}) => {
			if (data && data.length > 0) {
				setPages([...pages, ...data])
			}
		})
  }, [])

	return (
		<>
			<Header/>
			<div className={styles.container}>
				<div className={styles.searchField}>
					<div>
						<Button size="large" onClick={addPage} variant="contained" style={{verticalAlign: 'bottom'}}>
							<AddIcon/>
						</Button>
					</div>
				</div>
				<DataTable rows={pages} removeFn={removeFn} saveFn={saveFn} setPages={setPages}/>
			</div>
			{newPage && <Add open={newPage} setOpen={setNewPage} />}
		</>
	);
}

export default PageManagement;
