import React from 'react';
import styles from './header.module.scss';
import { useAuthDispatch, logout, useAuthState } from '../../Context';
import { Button, Hidden } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import HeaderLinks from './HeaderLinks';
import NavDrawer from './NavDrawer';

export default function Header(props) {
	const dispatch = useAuthDispatch();
  const userDetails = useAuthState();
  const history = useHistory();
	const handleLogout = () => {
		logout(dispatch);
    history.push('/login')
	};
  return (
    <div className={styles.header}>
      <Hidden mdUp>
        <NavDrawer/>
      </Hidden>
      <Hidden smDown>
        <HeaderLinks/>
      </Hidden>
      <div className={styles.right}>
        {userDetails.user.firstname + ' ' + userDetails.user.lastname}
        <Button style={{color:"#FFF", border: "1px solid #FFF", margin: "0 1rem"}} onClick={handleLogout}>
          Logout
        </Button>
      </div>
    </div>
  );
};
