require('dotenv').config()
const API_SERVER = process.env.REACT_APP_API_SERVER || 'https://api.lcaustin.org'
const PHOTO_SERVER = process.env.REACT_APP_PHOTO_SERVER || 'https://static.lcaustin.org'
const STATIC_SERVER = process.env.REACT_APP_STATIC_SERVER || 'https://static.lcaustin.org'
const PAGES = [
  {name:'메인', uri:'main'},
  {name:'교회소개', uri:'introduction'},
  {name:'영아부', uri:'nursery'},
  {name:'유아부', uri:'preschool'},
  {name:'초등부', uri:'elementary'},
  {name:'중고등부', uri:'youth'},
  {name:'대학부', uri:'college'},
  {name:'English Minstry', uri:'englishministry'},
  {name:'청년부', uri:'youngadult'},
  {name:'에노스', uri:'enos'},
  {name:'커피브레이크', uri:'coffeebreak'},
  {name:'신구약맥잡기', uri:'biblepanorama'},
  {name:'크라운재정교실', uri:'crownfinancialministries'},
  {name:'가온토요학교', uri:'gaonschool'},
  {name:'선교지', uri:'mission'}
]

export {
  API_SERVER,
  PHOTO_SERVER,
  STATIC_SERVER,
  PAGES
}