import React, { useState } from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid, Table, TableCell, TableBody, TableContainer, TableHead, TableRow, TextField, FormControl, FormLabel, Radio, RadioGroup, FormControlLabel } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import { API_SERVER, STATIC_SERVER } from '../../Config/constants';
import Slider from '@material-ui/core/Slider';
import { useAuthState } from '../../Context';
import DatePicker from '../../Components/DatePicker';

export default function Edit({ open, setOpenEdit, banner={}, setBanner }) {
  const [image, setImage] = useState(null)
	const userDetails = useAuthState()
  const [title, setTitle] = useState(banner.title)
  const [subtitle, setSubtitle] = useState(banner.subtitle)
  const [linkname, setLinkname] = useState(banner.linkname || '')
  const [link, setLink] = useState(banner.link || '')
  const [linkname2, setLinkname2] = useState(banner.linkname2 || '')
  const [link2, setLink2] = useState(banner.link2 || '')
  const [target, setTarget] = useState(banner.target || '_blank')
  const [startDate, setStartDate] = useState(banner.start_date)
  const [endDate, setEndDate] = useState(banner.end_date)
  let [textstyle, setTextstyle] = useState(banner.textstyle || '')
  const [fontColor, setFontColor] = useState(banner.fontcolor || '')
  const [backgroundPosition, setBackgroundPosition] = useState(banner.background_position)

  const handleClose = () => {
    setOpenEdit(false)
  }

  const uploadFile = (e) => {
    const list = []
    let file = e.target.files[0]
    let reader = new FileReader()
    reader.addEventListener("load", function () {
      let img = new Image()
      img.src = this.result
      img.name = file.name
      img.onload = () => {
        setImage(img)
      };
    }, false)

    reader.readAsDataURL(file)
  }
  const handleUpload = async () => {
      const formData = new FormData()
      if (image) {
        formData.append('file', image.src)
        formData.append('file_name', image.name)
        formData.append('width', image.width)
        formData.append('height', image.height)
      }
      if (textstyle === "[object Object]") {
        textstyle = ''
        setTextstyle(textstyle)
      }
      formData.append('imagechanged', image ? true : false)
      formData.append('title', title)
      formData.append('subtitle', subtitle)
      formData.append('linkname', linkname)
      formData.append('link', link)
      formData.append('linkname2', linkname2)
      formData.append('link2', link2)
      formData.append('target', target)
      formData.append('start_date', startDate)
      formData.append('end_date', endDate)
      formData.append('textstyle', textstyle || '')
      formData.append('fontcolor', fontColor || '')
      formData.append('background_position', backgroundPosition)
      formData.append('creator', userDetails.user.email)
      const { data } = await axios({
        method: 'put',
        url: `${API_SERVER}/api/banner/edit/${banner._id}`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" }
      })
      const obj = {img_path: data.data.img_path}
      formData.forEach((v, k) => obj[k] = v)
      setBanner(Object.assign(banner, obj))
      setImage(null)
      handleClose(true)
  }
  return (
    <div>
      <Dialog
        maxWidth="xl"
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
           배너 수정
        </DialogTitle>
        <DialogContent align="center">
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <TextField margin="dense" variant="outlined" label="Title" name="image-title" style={{width:"100%"}} onChange={(e) => setTitle(e.target.value)} value={title}/>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <TextField margin="dense" variant="outlined" label="Sub title" name="image-sub-title" style={{width:"100%"}} onChange={(e) => setSubtitle(e.target.value)} value={subtitle}/>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" style={{
                    display: "flex", justifyContent: "space-between"
                  }}>
                    <TextField margin="dense" variant="outlined" label="버튼명" name="image-button-name" style={{width:"48%"}} onChange={(e) => setLinkname(e.target.value)} value={linkname}/>
                    <TextField margin="dense" variant="outlined" label="버튼링크" name="image-button-name" style={{width:"48%"}} onChange={(e) => setLink(e.target.value)} value={link}/>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" style={{
                    display: "flex", justifyContent: "space-between"
                  }}>
                    <TextField margin="dense" variant="outlined" label="버튼명2" name="image-button-name" style={{width:"48%"}} onChange={(e) => setLinkname2(e.target.value)} value={linkname2}/>
                    <TextField margin="dense" variant="outlined" label="버튼링크2" name="image-button-name" style={{width:"48%"}} onChange={(e) => setLink2(e.target.value)} value={link2}/>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <FormControl component="fieldset">
                      <FormLabel component="legend">링크방식</FormLabel>
                      <RadioGroup row aria-label="target" name="target" defaultValue={target} value={target} onChange={(e) => setTarget(e.target.value)}>
                        <FormControlLabel value="_self" control={<Radio />} label="현재 탭에서 열기" />
                        <FormControlLabel value="_blank" control={<Radio />} label="새 탭에서 열기" />
                      </RadioGroup>
                    </FormControl>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <DatePicker label="시작일" defaultDate={startDate} onDateChange={setStartDate} display="inline-block"/>
                    <DatePicker label="종료일" defaultDate={endDate} onDateChange={setEndDate} display="inline-block"/>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <TextField variant="outlined" defaultValue={backgroundPosition} label="배경이미지 위치" onChange={(e) => setBackgroundPosition(e.target.value)}/>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" style={{
                    display: "flex", justifyContent: "space-between"
                  }}>
                    <TextField variant="outlined" defaultValue={JSON.stringify(textstyle || {})} label="텍스트 스타일" style={{width:"48%"}} onChange={(e) => setTextstyle(JSON.stringify(e.target.value || {}))}/>
                    <TextField variant="outlined" defaultValue={fontColor}  label="글자색" style={{width:"48%"}} onChange={(e) => setFontColor(e.target.value)}/>
                 </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <input style={{
                      width: "calc(100% - 2rem)",
                      fontSize: "1rem",
                      backgroundColor: "#e3e3e3",
                      padding: "1rem"
                    }} accept="image/*"  type="file" className="form-control" onChange={uploadFile}/>
                  </TableCell>
                </TableRow>
                {image &&
                <TableRow>
                  <TableCell component="th" scope="row">
                    <img src={image.src} style={{width: "100%"}}/>
                  </TableCell>
                </TableRow>
                }
                {!image &&
                <TableRow>
                  <TableCell component="th" scope="row">
                    <img src={STATIC_SERVER+banner.img_path} style={{width: "100%"}}/>
                  </TableCell>
                </TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            취소
          </Button>
          <Button onClick={handleUpload} color="primary">
            저장
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
