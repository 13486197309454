import React, { useState } from 'react';
import _ from 'lodash';
import querystring from 'querystring';
import axios from 'axios';
import { Button, TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import Header from '../../Components/Header';
import styles from './bulletin.module.scss';
import Add from './BulletinAdd';
import DataTable from './DataTable';
import { API_SERVER } from '../../Config/constants';

export default function Bulletin(props) {
	const [count, setCount] = useState(0)
	const [newBulletin, setNewBulletin] = useState(false)
	const [keyword, setKeyword] = useState('')
  const [bulletins, setBulletins] = useState([]);
	const [paging, setPaging] = useState({skip: 0, limit: 10})
	const searchBulletin = async (reset=false) => {
		if (reset) {
			paging.skip = 0
			setPaging(paging)
		}
		const params = {
			skip: reset ? 0 : (count || paging.skip),
			limit: paging.limit
		}
		if (keyword) {
			params.title = keyword;
		}
		const { data } = await axios.get(`${API_SERVER}/api/bulletin?${querystring.encode(params)}`);
		if (reset) {
			setCount(data.length)
			setBulletins(data)
		} else {
			setCount(count + data.length)
			setBulletins([...bulletins, ...data])
		}
	}

	const addBulletin = () => {
		setNewBulletin(true)
	}

	const removeFn = id => {
		setBulletins(bulletins.filter(v => v._id !== id))
	}

	const loadMore = () => {
		paging.skip += paging.limit
		setPaging(paging)
		searchBulletin()
	}

  React.useEffect(() => {
		searchBulletin()
  }, [])

	return (
		<>
			<Header/>
			<div className={styles.container}>
				<div className={styles.searchField}>
					<div>
						<TextField id="bulletin-search-keyword" label="검색어"
							onChange={(event) => setKeyword(event.target.value)} />
						<Button size="large" onClick={() => searchBulletin(true)} variant="contained" style={{verticalAlign: 'bottom'}}>
							<SearchIcon/>
						</Button>
						&nbsp; &nbsp;
						<Button size="large" onClick={addBulletin} variant="contained" style={{verticalAlign: 'bottom'}}>
							<AddIcon/>
						</Button>
					</div>
				</div>
				<DataTable rows={bulletins} removeFn={removeFn} setBulletins={setBulletins} loadMore={loadMore}/>
			</div>
			{newBulletin && <Add open={newBulletin} setOpen={setNewBulletin} />}
		</>
	);
};
