import React, { useState } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import EventIcon from '@material-ui/icons/Event';
import SettingsIcon from '@material-ui/icons/Settings';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { LensTwoTone } from '@material-ui/icons';
import Delete from './JuboDelete';
import { STATIC_SERVER } from '../../Config/constants';

export default function JuboCard({ jubo={}, setJubo, removeFn }) {

  const handleClickDelete = () => {
    setOpenDelete(true);
  }
	const [openDelete, setOpenDelete] = useState(false);
  const openFile = (url) => {
    window.open(url)
  }

  return (
    <>
      <Card variant="outlined">
        <CardContent style={{
          padding: "0.5rem 1.2rem"
        }}>
          <Typography gutterBottom variant="subtitle1" component="h6"
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "10px"
            }}
          >
            <div>
              {jubo.date} 일자 주보
            </div>
            {jubo.file_name &&
              <Button variant="outlined" color="primary" size="small" align="right" onClick={() => openFile(STATIC_SERVER+'/'+jubo.file_path)}>
                {jubo.file_name}
              </Button>
            }
            {jubo.old &&
              <div>
                {jubo.old.page1 &&
                  <Button variant="outlined" color="primary" size="small" align="right" onClick={() => openFile(STATIC_SERVER+'/'+jubo.old.page1)}>
                    페이지1
                  </Button>
                }
                {jubo.old.page2 &&
                  <Button variant="outlined" color="primary" size="small" align="right" onClick={() => openFile(STATIC_SERVER+'/'+jubo.old.page2)}>
                    페이지2
                  </Button>
                }
              </div>
            }
          </Typography>
          <Typography variant="subtitle2" color="textSecondary"
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "10px"
            }}>
            <div>
              {jubo.creator &&
                <div style={{
                  display:"inline-block",
                  verticalAlign: "top",
                  paddingTop: "3px"
                }}>
                  Uploader: {jubo.creator}
                </div>
              }
            </div>
            <div style={{
                display:"inline-block",
                verticalAlign: "top",
                paddingTop: "3px",
                letterSpacing: 10,
                cursor: "pointer"
              }}>
                <DeleteIcon onClick={handleClickDelete}/>
            </div>
          </Typography>
        </CardContent>
      </Card>
      <Delete open={openDelete} setOpenDelete={setOpenDelete} jubo={jubo} removeFn={removeFn}/>
    </>
  );
}
