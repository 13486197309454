import React, { useState } from 'react';
import _ from 'lodash';
import querystring from 'querystring';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Table, TableCell, TableBody, TableContainer, TableHead, TableRow, TextField, MenuItem, FormControl, InputLabel, Select } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { API_SERVER } from '../../Config/constants';
import { useAuthState } from '../../Context';

export default function Edit({ open, setOpenEdit, saveFn, staff, setStaff }) {
  const [name, setName] = useState(staff.name)
  const [role, setRole] = useState(staff.role)
  const [status, setStatus] = useState(staff.status)
  const [image, setImage] = useState(staff.image)
  const [back, setBack] = useState(staff.back)
  const userDetails = useAuthState()

  const handleClose = () => {
    setOpenEdit(false)
  }

  const upload = (e, side) => {
    let file = e.target.files[0]
    let reader = new FileReader()
    reader.addEventListener("load", function () {
      let img = new Image()
      img.src = this.result
      img.onload = async () => {
        const formData = new FormData()
        formData.append('file', img.src)
        formData.append('width', img.width)
        formData.append('height', img.height)
        formData.append('file_name', file.name)
        formData.append('creator', userDetails.user.email)
        const { data } = await axios({
          method: 'post',
          url: `${API_SERVER}/api/image/add`,
          data: formData,
          headers: { "Content-Type": "multipart/form-data" }
        })
        if (side === 'front') {
          setImage(data.data._id)
        } else {
          setBack(data.data._id)
        }
      };
    }, false)
    reader.readAsDataURL(file)
  }

  const uploadFront = (e) => {
    upload(e, 'front')
  }
  const uploadBack = (e) => {
    upload(e, 'back')
  }
  const handleSave = async () => {
    await axios.put(`${API_SERVER}/api/staff/${staff._id}`, {
      name, role, status, image, back
    })
    window.location.reload()
  }

  const roles = ['목사','전도사','간사','지휘자','장로','권사','안수집사']

  return (
    <div>
      <Dialog
        maxWidth="md"
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
           섬기는이 수정
        </DialogTitle>
        <DialogContent align="center">
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                    <TextField margin="dense" variant="outlined" label="이름" defaultValue={staff.name} name="name" onChange={(e) => setName(e.target.value)} style={{width:"100%"}}/>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                    {/* <TextField margin="dense" variant="outlined" label="직분 (예: 목사/장로/권사/안수집사)" name="role" onChange={(e) => setRole(e.target.value)} style={{width:"100%"}}/> */}
                      <FormControl style={{width: "100%"}}>
                        <InputLabel id="staff-role-add">직분</InputLabel>
                        <Select
                          value={staff.role}
                          labelId="staff-role-add"
                          onChange={(e) => setRole(e.target.value)}
                        >
                          {roles.map((v, idx) => <MenuItem key={idx} value={v}>{v}</MenuItem>)}
                        </Select>
                      </FormControl>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      <TextField margin="dense" variant="outlined" defaultValue={staff.status} label="타이틀 (예: 시무/협동)" name="status" onChange={(e) => setStatus(e.target.value)} style={{width:"100%"}}/>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      앞면 <input style={{
                        width: "calc(100% - 2rem)",
                        fontSize: "1rem",
                        backgroundColor: "#e3e3e3",
                        padding: "1rem"
                      }} accept="image/*"  type="file" className="form-control" onChange={uploadFront}/>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      뒷면 <input style={{
                        width: "calc(100% - 2rem)",
                        fontSize: "1rem",
                        backgroundColor: "#e3e3e3",
                        padding: "1rem"
                      }} accept="image/*"  type="file" className="form-control" onChange={uploadBack}/>
                    </TableCell>
                  </TableRow>

                </TableBody>
              </Table>
            </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            취소
          </Button>
          <Button onClick={handleSave} color="primary">
            저장
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
