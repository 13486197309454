import React from 'react';
import Login from '../Pages/Login';
import Signup from '../Pages/Signup';
import NotFound from '../Pages/NotFound';
import { MenuData } from '../Components/Header/MenuData';
import Video from '../Pages/Video';

const routes = [
	{
		path: '/login',
		component: Login,
		isPrivate: false,
	},
	// {
	// 	path: '/signup',
	// 	component: Signup,
	// 	isPrivate: false,
	// },
	...MenuData,
	{
		path: '/',
		component: Video,
		isPrivate: true,
	},
	{
		path: '/*',
		component: NotFound,
		isPrivate: true,
	},
];

export default routes;
