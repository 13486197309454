import React, { useState } from 'react';
import moment from 'moment';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Edit from './ChronicleEdit';
import Delete from './ChronicleDelete';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#D5D5D5',
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const useStyles = makeStyles({
  table: {
    // minWidth: 700,
  },
});

export default function DataTable({ rows=[], removeFn, saveFn, setEvts, }) {
  const classes = useStyles();
  const [openDelete, setOpenDelete] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [evt, setEvt] = useState()

  const handleClickEdit = (v) => {
    setEvt(v)
    setOpenEdit(true)
  };

  const handleClickDelete = (v) => {
    setEvt(v)
    setOpenDelete(true)
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>제목</StyledTableCell>
              <StyledTableCell>시작일</StyledTableCell>
              <StyledTableCell>종료일</StyledTableCell>
              <StyledTableCell align="right" width="10%">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, idx) => (
              <StyledTableRow key={idx}>
                <StyledTableCell component="th" scope="row">
                  {row.title}
                </StyledTableCell>
                <StyledTableCell>
                  {row.start}
                </StyledTableCell>
                <StyledTableCell>
                  {row.end}
                </StyledTableCell>
                <StyledTableCell align="right">
                  <EditIcon onClick={() => handleClickEdit(row)}/>&nbsp;&nbsp;
                  <DeleteIcon onClick={() => handleClickDelete(row)}/>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {evt && <Delete removeFn={removeFn} open={openDelete} setOpenDelete={setOpenDelete} evt={evt}/>}
      {evt && <Edit saveFn={saveFn} open={openEdit} setOpenEdit={setOpenEdit} evt={evt} setEvt={setEvt}/>}
    </>
  );
}
