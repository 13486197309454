import React, { useState } from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid, Table, TableCell, TableBody, TableContainer, TableHead, TableRow, TextField, FormControl, FormLabel, Radio, RadioGroup, FormControlLabel } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import { API_SERVER } from '../../Config/constants';
import Slider from '@material-ui/core/Slider';
import { useAuthState } from '../../Context';
import DatePicker from '../../Components/DatePicker';

export default function Add({ open, setOpen }) {
  const [image, setImage] = useState(null)
	const userDetails = useAuthState()
  const [title, setTitle] = useState('')
  const [subtitle, setSubtitle] = useState('')
  const [linkname, setLinkname] = useState('')
  const [link, setLink] = useState('')
  const [linkname2, setLinkname2] = useState('')
  const [link2, setLink2] = useState('')
  const [target, setTarget] = useState('_blank')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [backgroundPosition, setBackgroundPosition] = useState('40%') // 40% default
  let [textstyle, setTextstyle] = useState({})
  const [fontColor, setFontColor] = useState('')

  const handleClose = () => {
    setOpen(false)
  }
  const uploadFile = (e) => {
    const list = []
    let file = e.target.files[0]
    let reader = new FileReader()
    reader.addEventListener("load", function () {
      let img = new Image()
      img.src = this.result
      img.name = file.name
      img.file = file;
      img.onload = () => {
        setImage(img)
      };
    }, false)

    reader.readAsDataURL(file)
  }
  const handleUpload = async () => {
      if (textstyle === "[object Object]") {
        textstyle = ''
        setTextstyle(textstyle)
      }
      const formData = new FormData()
      formData.append('file', image.file)
      formData.append('file_name', image.name)
      formData.append('width', image.width)
      formData.append('height', image.height)
      formData.append('title', title)
      formData.append('subtitle', subtitle)
      formData.append('linkname', linkname)
      formData.append('link', link)
      formData.append('linkname2', linkname2)
      formData.append('link2', link2)
      formData.append('target', target)
      formData.append('start_date', startDate)
      formData.append('end_date', endDate)
      formData.append('background_position', backgroundPosition)
      formData.append('textstyle', textstyle || '')
      formData.append('fontcolor', fontColor || '')
      formData.append('creator', userDetails.user.email)
      const res = await axios({
        method: 'post',
        url: `${API_SERVER}/api/banner/addraw`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" }
      })
      setImage(null)
      handleClose(true)
      window.location.reload()
  }

  return (
    <div>
      <Dialog
        maxWidth="md"
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
           배너 추가
        </DialogTitle>
        <DialogContent align="center">
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <TextField margin="dense" variant="outlined" label="Title" name="image-title" style={{width:"100%"}} onChange={(e) => setTitle(e.target.value)}/>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <TextField margin="dense" variant="outlined" label="Sub title" name="image-sub-title" style={{width:"100%"}} onChange={(e) => setSubtitle(e.target.value)}/>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" style={{
                    display: "flex", justifyContent: "space-between"
                  }}>
                    <TextField margin="dense" variant="outlined" label="버튼명" name="image-button-name" style={{width:"48%"}} onChange={(e) => setLinkname(e.target.value)}/>
                    <TextField margin="dense" variant="outlined" label="버튼링크" name="image-button-name" style={{width:"48%"}} onChange={(e) => setLink(e.target.value)}/>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" style={{
                    display: "flex", justifyContent: "space-between"
                  }}>
                    <TextField margin="dense" variant="outlined" label="버튼명2" name="image-button-name" style={{width:"48%"}} onChange={(e) => setLinkname2(e.target.value)}/>
                    <TextField margin="dense" variant="outlined" label="버튼링크2" name="image-button-name" style={{width:"48%"}} onChange={(e) => setLink2(e.target.value)}/>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <FormControl component="fieldset">
                      <FormLabel component="legend">링크방식</FormLabel>
                      <RadioGroup row aria-label="target" name="target" value={target} onChange={(e) => setTarget(e.target.value)}>
                        <FormControlLabel value="_self" control={<Radio />} label="현재 탭에서 열기" />
                        <FormControlLabel value="_blank" control={<Radio />} label="새 탭에서 열기" />
                      </RadioGroup>
                    </FormControl>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <DatePicker label="시작일" onDateChange={setStartDate} display="inline-block"/>
                    <DatePicker label="종료일" onDateChange={setEndDate} display="inline-block"/>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <TextField variant="outlined" label="배경이미지 위치" onChange={(e) => setBackgroundPosition(e.target.value)}/>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" style={{
                    display: "flex", justifyContent: "space-between"
                  }}>
                    <TextField variant="outlined" disabled defaultValue="{}"  label="텍스트 스타일" style={{width:"48%"}} onChange={(e) => setTextstyle(JSON.stringify(e.target.value || {}))}/>
                    <TextField variant="outlined" defaultValue=""  label="글자색" style={{width:"48%"}} onChange={(e) => setFontColor(e.target.value)}/>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <input style={{
                      width: "calc(100% - 2rem)",
                      fontSize: "1rem",
                      backgroundColor: "#e3e3e3",
                      padding: "1rem"
                    }} accept="image/jpeg, image/jpg"  type="file" className="form-control" onChange={uploadFile}/>
                  </TableCell>
                </TableRow>
                {image &&
                <TableRow>
                  <TableCell component="th" scope="row">
                    <img src={image.src} style={{width: "100%"}}/>
                  </TableCell>
                </TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            취소
          </Button>
          <Button onClick={handleUpload} color="primary">
            업로드
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
