import React, { useState } from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid, Table, TableCell, TableBody, TableContainer, TableHead, TableRow, TextField, MenuItem, FormControl, InputLabel, Select } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { API_SERVER } from '../../Config/constants';
import { useAuthState } from '../../Context';
import { SettingsVoiceTwoTone } from '@material-ui/icons';

export default function Edit({ evt={}, saveFn, open, setOpenEdit }) {

  const [loading, setLoading] = useState(false)
  const userDetails = useAuthState()

  const [title, setTitle] = useState(evt.title)
  const [start, setStart] = useState(evt.start)
  const [end, setEnd] = useState(evt.end)
  const handleClose = () => {
    setOpenEdit(false)
  }

  const handleSave = async () => {
      setLoading(true)
      const { data } = await axios({
        method: 'put',
        url: `${API_SERVER}/api/chronicle/${evt._id}`,
        data: {
          start,
          end,
          title,
          creator: userDetails.user.email
        }
      })
      saveFn(data)
      setOpenEdit(false)
  }

  return (
    <div>
      <Dialog
        maxWidth="md"
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
           연혁 수정
        </DialogTitle>
        <DialogContent align="center">
        <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <TextField margin="dense" variant="outlined" defaultValue={title} label="행사" name="title" onChange={(e) => setTitle(e.target.value)} style={{width:"100%"}}/>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      <TextField type="date" margin="dense" variant="outlined" label="시작일" defaultValue={start} name="start" onChange={(e) => setStart(e.target.value)} style={{width:"100%"}}/>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      <TextField type="date" margin="dense" InputLabelProps={{ shrink: true }} variant="outlined" label="종료일" defaultValue={end} name="end" onChange={(e) => setEnd(e.target.value)} style={{width:"100%"}}/>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            취소
          </Button>
          <Button onClick={handleSave} color="primary">
            저장
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
