import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import Header from '../../Components/Header';
import { useAuthDispatch, logout, useAuthState } from '../../Context';
import styles from './banner.module.scss';
import { useHistory } from "react-router-dom";
import BannerCard from "./BannerCard";
import { API_SERVER } from '../../Config/constants';
import { Button, TextField } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import _ from 'lodash';
import querystring from 'querystring';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Add from './BannerAdd';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';

function Banner(props) {
	const dispatch = useAuthDispatch();
	const userDetails = useAuthState();
	const history = useHistory()
	const [count, setCount] = useState(0)
	const [newBanner, setNewBanner] = useState(false)
	const [keyword, setKeyword] = useState('')
  const [banners, setBanners] = useState([]);
  const [banner, setBanner] = useState({});
	const [paging, setPaging] = useState({skip: 0, limit: 20})

	const searchBanner = async (reset=false) => {
		if (reset) {
			setCount(0)
			paging.skip = 0
			setPaging(paging)
			setBanners([])
		}
		const params = {
			skip: reset ? 0 : (count || paging.skip),
			limit: paging.limit
		}
		if (keyword) {
			params.title = keyword;
		}
		const { data } = await axios.get(`${API_SERVER}/api/banner?${querystring.encode(params)}`);
		if (data && data.length > 0) {
			setCount(count + data.length)
			setBanners([...banners, ...data])
		}
	}

	const viewMore = num => {
		paging.skip = count
		paging.limit = num
		setPaging(paging)
		searchBanner()
	}
	const addBanner = () => {
		setNewBanner(true)
	}
	const removeFn = id => {
		setBanners(banners.filter(v => v._id !== id))
	}

  React.useEffect(() => {
		searchBanner()
  }, [])

	return (
		<>
			<Header/>
			<div className={styles.container}>
				<div className={styles.searchField}>
					<div>
						<TextField id="banner-search-keyword" label="검색어"
							onChange={(event) => setKeyword(event.target.value)} />
						<Button size="large" onClick={searchBanner} variant="contained" style={{verticalAlign: 'bottom'}}>
							<SearchIcon/>
						</Button>
						&nbsp; &nbsp;
						<Button size="large" onClick={addBanner} variant="contained" style={{verticalAlign: 'bottom'}}>
							<AddIcon/>
						</Button>
					</div>
				</div>
				<Grid container spacing={1} style={{justifyContent: "space-around"}}>
					<Grid container item xs={12} spacing={3}>
						{banners.map((banner, index) => (
							<Grid item xs={12} sm={6} key={index}>
								<BannerCard key={index} banner={banner} setBanner={setBanner} removeFn={removeFn}/>
							</Grid>
						))}
						<Grid item xs={12} sm={6} key={banners.length} align="center">
							<Card variant="outlined"
								style={{
									height: "130px",
									display: "flex",
									flexDirection: "column",
									justifyContent: "center"
								}}
							>
								<CardContent>
									<Typography variant="h6" component="h2">
										Total {banners.length} items
									</Typography>
									<br/>
									<Typography color="textSecondary" gutterBottom>
										<Button variant="contained" size="large"
											onClick={() => viewMore(10)}
										>
											10 more
										</Button>
										&nbsp;
										<Button variant="contained" size="large"
											onClick={() => viewMore(20)}
										>
											20 more
										</Button>
									</Typography>
								</CardContent>
							</Card>
						</Grid>
					</Grid>
				</Grid>
			</div>
			{newBanner && <Add open={newBanner} setOpen={setNewBanner}/>}
		</>
	);
}

export default Banner;
