import React, { useState } from 'react';
import _ from 'lodash';
import querystring from 'querystring';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import Header from '../../Components/Header';
import { useAuthDispatch, logout, useAuthState } from '../../Context';
import styles from './staff.module.scss';
import { useHistory } from "react-router-dom";
import PhotoCard from "../Photo/PhotoCard";
import { API_SERVER } from '../../Config/constants';
import { Button, TextField } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Add from './StaffAdd';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import DataTable from './DataTable';

function Staff(props) {
	const dispatch = useAuthDispatch();
	const userDetails = useAuthState();
	const history = useHistory()
	const [newStaff, setNewStaff] = useState(false)
  const [staffs, setStaffs] = useState([]);
  const [role, setRole] = useState('목사');
	const addStaff = () => {
		setNewStaff(true)
	}
	const removeFn = id => {
		setStaffs(staffs.filter(v => v._id !== id))
	}
	const saveFn = staff => {
		staffs.forEach(el => {
			if (el._id === staff._id) {
				Object.assign(el, staff)
			}
		})
		setStaffs(staffs)
	}
	const handleChange = (role) => {
		setRole(role)
		getData(role)
	}

	const getData = async (role) => {
		const param = { limit: 200, role }
		const { data } = await axios.get(`${API_SERVER}/api/staff?${querystring.encode(param)}`) // show all
		setStaffs(data || [])
	}

  React.useEffect(() => {
		getData('목사')
  }, [])

	const roles = ['목사','전도사','간사','지휘자','장로','권사','안수집사']

	return (
		<>
			<Header/>
			<div className={styles.container}>
				<div className={styles.searchField}>
					<FormControl className={styles.formControl}>
						<InputLabel id="staff-role">직분</InputLabel>
						<Select
							value={role}
							labelId="staff-role"
							onChange={(e) => handleChange(e.target.value)}
						>
							{roles.map((v, idx) => <MenuItem key={idx} value={v}>{v}</MenuItem>)}
						</Select>
					</FormControl>
					<div>
						<Button size="large" onClick={addStaff} variant="contained" style={{verticalAlign: 'bottom'}}>
							<AddIcon/>
						</Button>
					</div>
				</div>
				<DataTable rows={staffs} removeFn={removeFn} saveFn={saveFn} setStaffs={setStaffs}/>
			</div>
			{newStaff && <Add open={newStaff} setOpen={setNewStaff} />}
		</>
	);
}

export default Staff;
