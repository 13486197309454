import { API_SERVER } from "../Config/constants";

export async function loginUser(dispatch, loginPayload) {
	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(loginPayload),
	};

	try {
		dispatch({ type: 'REQUEST_LOGIN' });
		let response = await fetch(`${API_SERVER}/api/user/signin`, requestOptions);
		let { data } = await response.json();
		if (data && data.jwt) {
			dispatch({ type: 'LOGIN_SUCCESS', payload: {user: data} });
			localStorage.setItem('currentUser', JSON.stringify(data));
			return {user: data};
		}

		dispatch({ type: 'LOGIN_ERROR', error: data });
		return;
	} catch (error) {
		dispatch({ type: 'LOGIN_ERROR', error: error });
		console.log(error);
	}
}

export async function signupUser(dispatch, signupPaylord) {
	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(signupPaylord),
	};

	try {
		dispatch({ type: 'REQUEST_LOGIN' });
		let response = await fetch(`${API_SERVER}/api/user/signup`, requestOptions);
		let { data } = await response.json();
		if (data && data.jwt) {
			dispatch({ type: 'LOGIN_SUCCESS', payload: {user: data} });
			localStorage.setItem('currentUser', JSON.stringify(data));
			return {user: data};
		}

		dispatch({ type: 'LOGIN_ERROR', error: data });
		return;
	} catch (error) {
		dispatch({ type: 'LOGIN_ERROR', error: error });
		console.log(error);
	}
}

export async function logout(dispatch) {
	dispatch({ type: 'LOGOUT' });
	localStorage.removeItem('currentUser');
}
