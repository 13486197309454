import React, { useState } from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid, Table, TableCell, TableBody, TableContainer, TableHead, TableRow, TextField, MenuItem, FormControl, InputLabel, Select } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { API_SERVER, PAGES } from '../../Config/constants';
import { useAuthState } from '../../Context';

export default function Edit({ page={}, open, setOpenEdit }) {

  const [image, setImage] = useState(null)
  const [loading, setLoading] = useState(false)
  const userDetails = useAuthState()

  const [label, setLabel] = useState(page.label)
  const [title, setTitle] = useState(page.title)
  const [subtitle, setSubtitle] = useState(page.subtitle)
  const [introvideourl, setIntrovideourl] = useState(page.introvideourl)
  const [verse, setVerse] = useState(page.verse)
  const [uri, setUri] = useState(page.uri)
  const [values, setValues] = useState(page.values || [])
  const [banner, setBanner] = useState(page.banner._id)
  const [key, setKey] = useState('')
  const [value, setValue] = useState('')
  const [duplicate, setDuplicate] = useState(false)
  const handleClose = () => {
    setOpenEdit(false)
  }
  const selectPage = (uri) => {
    const page = PAGES.find(el => el.uri === uri)
    setLabel(page.name)
    setUri(page.uri)
  }
  const changeValue = (key, value) => {
    values.forEach(v => {
      if (v.key === key) {
        v.value = value
      }
    })
    setValues(values)
  }
  const uploadFile = (e) => {
    let file = e.target.files[0]
    let reader = new FileReader()
    reader.addEventListener("load", function () {
      let img = new Image()
      img.src = this.result
      img.onload = async () => {
        setImage(img)
        const formData = new FormData()
        formData.append('file', img.src)
        formData.append('width', img.width)
        formData.append('height', img.height)
        formData.append('file_name', file.name)
        formData.append('creator', userDetails.user.email)
        const { data } = await axios({
          method: 'post',
          url: `${API_SERVER}/api/image/add`,
          data: formData,
          headers: { "Content-Type": "multipart/form-data" }
        })
        setBanner(data.data._id)
      };
    }, false)

    reader.readAsDataURL(file)
  }

  const handleSave = async () => {
      setLoading(true)
      const { data } = await axios({
        method: 'put',
        url: `${API_SERVER}/api/pagemanagement/${page._id}`,
        data: {
          label, uri, title, subtitle, verse, banner, values, introvideourl, creator: userDetails.user.email
        }
      })
      window.location.reload()
  }

  const addValues = () => {
    if (!key || !value) {
      return
    }
    let dup = values.find(el => el.key === key)
    if (dup) {
      setDuplicate(true)
      return
    }
    setDuplicate(false)
    setValues([...values, {key, value}])
    setKey('')
    setValue('')
  }
  const removeValue = key => {
    setValues(values.filter(el => el.key !== key))
  }

  return (
    <div>
      <Dialog
        maxWidth="md"
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
           페이지 수정
        </DialogTitle>
        <DialogContent align="center">
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {/* <TextField margin="dense" variant="outlined" label="이름" name="label" onChange={(e) => setLabel(e.target.value)}style={{width:"100%"}}/> */}
                      <FormControl style={{width: "100%"}}>
                        <InputLabel id="pagemanagement-page">페이지</InputLabel>
                        <Select
                          defaultValue={page.uri}
                          labelId="pagemanagement-page"
                          onChange={(e) => selectPage(e.target.value)}
                        >
                          {PAGES.map((page) => (
                            <MenuItem key={page.uri} value={page.uri}>{page.name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                    <TextField margin="dense" variant="outlined" label="타이틀" name="title" defaultValue={page.title} onChange={(e) => setTitle(e.target.value)} style={{width:"100%"}}/>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      <TextField margin="dense" variant="outlined" label="서브타이틀" name="subtitle" defaultValue={page.subtitle} onChange={(e) => setSubtitle(e.target.value)} style={{width:"100%"}}/>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      <TextField margin="dense" variant="outlined" label="말씀" name="verse" defaultValue={page.verse} onChange={(e) => setVerse(e.target.value)} style={{width:"100%"}}/>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      <TextField margin="dense" variant="outlined" label="소개영상URL" name="introvideourl" defaultValue={page.introvideourl} onChange={(e) => setIntrovideourl(e.target.value)} style={{width:"100%"}}/>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      <div style={{display: "flex", justifyContent: "space-between"}}>
                        <TextField margin="dense" variant="outlined"
                          onChange={(e) => setKey(e.target.value)}
                          value={key}
                          helperText={duplicate ? "이미 존재하는 Key 입니다 " : ""}
                          label="Key" fullWidth style={{marginRight: "1rem", width: "30%"}}/>
                        <TextField margin="dense" value={value} variant="outlined" onChange={(e) => setValue(e.target.value)} label="Value" fullWidth/>
                        <Button color="primary" onClick={addValues}>추가</Button>
                      </div>
                      {values.map(el => (
                        <div style={{display: "flex", justifyContent: "space-between"}} key={el.key}>
                          <TextField margin="dense" variant="outlined" label="Key" defaultValue={el.key} fullWidth style={{marginRight: "1rem", width: "30%"}}
                            InputProps={{
                              readOnly: true
                            }}/>
                          <TextField margin="dense" variant="outlined" label="Value" defaultValue={el.value} onChange={(e) => changeValue(el.key, e.target.value)} fullWidth/>
                          <Button color="secondary" onClick={() => removeValue(el.key)}>삭제</Button>
                        </div>
                      ))}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      <input style={{
                        width: "calc(100% - 2rem)",
                        fontSize: "1rem",
                        backgroundColor: "#e3e3e3",
                        padding: "1rem"
                      }} accept="image/*"  type="file" className="form-control" onChange={uploadFile}/>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      {page.banner && <img src={page.banner.host+page.banner.img_path} style={{width: "100%"}}/>}
                    </TableCell>
                  </TableRow>

                </TableBody>
              </Table>
            </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            취소
          </Button>
          <Button onClick={handleSave} color="primary">
            저장
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
